import React from "react"
import Drawer from "react-modern-drawer"
import "react-modern-drawer/dist/index.css"
import apaceLogo from "../../images/apace Logo_Main Logo.svg"
import hamburger from "../../images/hambuegr.svg"
import { Link } from "gatsby"
import {
  HiChevronDown,
  HiOutlineOfficeBuilding,
  HiOutlineUser,
  HiExternalLink,
} from "react-icons/hi"
const MobileMenu = ({}) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [activeMenu, setactive] = React.useState(false)
  const [shopperMenu, setShopperMenu] = React.useState(false)
  const [solutionsMenu, setSolutionsMenu] = React.useState(false)
  const [apceMenu, setapceMenu] = React.useState(false)
  const toggleDrawer = () => {
    setIsOpen(prevState => !prevState)
  }
  return (
    <div>
      <div className="px-4 py-6">
        <div className="flex justify-between" style={{ alignItems: "center" }}>
          <span
            onClick={toggleDrawer}
            className="flex self-center cursor-pointer"
          >
            <img src={hamburger} alt="menu" />
          </span>
          <div className="flex self-center">
            <Link to="/" className="logo">
              <img src={apaceLogo} alt="apace" className="w-[230px]" />
            </Link>
          </div>
          <div>
            <a
              href="/shoppers"
              className="for-shoppers bg-primary flex w-full justify-center items-center text-white text-[11px] font-medium text-center rounded-full transition-all duration-300 hover:shadow-xl px-3 py-2"
            >
              For Shoppers
            </a>
          </div>
        </div>
      </div>

      <Drawer open={isOpen} onClose={toggleDrawer} direction="right">
        <div className="flex flex-col  py-4">
          <Link to="/" className="">
            <img
              src={apaceLogo}
              alt="apace"
              className="w-[200px] mb-10 m-3 mt-10"
            />
          </Link>
          <div>
            <div
              className="flex justify-between relative mb-4 cursor-pointer px-4"
              onClick={() => {
                setactive(!activeMenu)
              }}
            >
              <Link to="/" className="nav_link_main ">
                Merchants
              </Link>
              <span className="flex self-center">
                <HiChevronDown
                  className={` transform transition-all duration-300 ${
                    activeMenu ? " rotate-180" : ""
                  }`}
                />
              </span>
            </div>

            <div
              className={`flex flex-col bg-slate-200 ${
                activeMenu
                  ? "max-h-96 transition-all duration-1000 ease-in-out overflow-hidden"
                  : "max-h-0  transition-all duration-700 ease-in-out overflow-hidden"
              }`}
            >
              <ul className="px-4 py-4">
                <li className="pb-3">
                  <a
                    href="#partner-with-apace"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Partner with Apace
                  </a>
                </li>
                <li className="pb-2 ">
                  <a
                    href="#merchant-portal"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Transparency
                  </a>
                </li>
                <li className="pb-2 ">
                  <a
                    href="#reimbursement"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Return Reimbursement
                  </a>
                </li>
                <li className="pb-2 ">
                  <a
                    href="#integration"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Integrate
                  </a>
                </li>
                <p className="a-menu-header text-slate-400 border-top-1 mt-0">
                  _______________________
                </p>
                <li className="mb-3">
                  <a
                    href="https://merchant.apacerefunds.com/auth/login"
                    className="menu-btn"
                    target="_blank"
                    onClick={toggleDrawer}
                  >
                    <button>
                      <HiOutlineOfficeBuilding /> Merchant sign in
                    </button>
                  </a>
                </li>
                <li className="text-center">
                  <a
                    href="https://merchant.apacerefunds.com/auth/signup"
                    className="a-menu-btn-link mt-2 underline"
                    target="_blank"
                    onClick={toggleDrawer}
                  >
                    Merchant sign up
                  </a>
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="pt-3">
            <div
              className="flex justify-between relative mb-4 cursor-pointer px-4"
              onClick={() => setShopperMenu(!shopperMenu)}
            >
              <Link to="/" className="nav_link_main ">
                Shoppers
              </Link>
              <span className="flex self-center">
                <HiChevronDown
                  className={` transform transition-all duration-300 ${
                    shopperMenu ? " rotate-180" : ""
                  }`}
                />
              </span>
            </div>

            <div
              className={`flex flex-col bg-slate-200  ${
                shopperMenu
                  ? "max-h-96 transition-all duration-1000 ease-in-out overflow-hidden"
                  : "max-h-0  transition-all duration-700 ease-in-out overflow-hidden"
              }`}
            >
              <ul className="px-4 py-4">
                <li className="pb-3">
                  <a href="/" className="nav_link">
                    Your instant refund
                  </a>
                </li>

                <li className=" pb-2">
                  <a href="/customers" className="menu-btn">
                    <button>
                      <HiOutlineOfficeBuilding />
                      Shopper sign In
                    </button>
                  </a>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="pt-3">
            <div
              className="flex justify-between relative mb-4 cursor-pointer px-4"
              onClick={() => {
                setSolutionsMenu(!solutionsMenu)
              }}
            >
              <Link to="/" className="nav_link_main ">
                Solutions
              </Link>
              <span className="flex self-center">
                <HiChevronDown
                  className={` transform transition-all duration-300 ${
                    solutionsMenu ? " rotate-180" : ""
                  }`}
                />
              </span>
            </div>

            <div
              className={`flex flex-col bg-slate-200  ${
                solutionsMenu
                  ? "max-h-96 transition-all duration-1000 ease-in-out overflow-hidden"
                  : "max-h-0  transition-all duration-700 ease-in-out overflow-hidden"
              }`}
            >
              <ul className="px-4 py-4">
                <li className="pb-3">
                  <a
                    href="#early-refund2"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    <strong>Early</strong>{" "}
                    <span style={{ fontWeight: "lighter" }}>
                      {" "}
                      Instant refund™
                    </span>
                  </a>
                </li>
                <li className="pb-3">
                  <a
                    href="#duly-refund2"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    <strong>Duly</strong>{" "}
                    <span style={{ fontWeight: "lighter" }}>
                      {" "}
                      Instant refund™
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="pt-3">
            <div
              className="flex justify-between relative mb-4 cursor-pointer px-4"
              onClick={() => {
                setapceMenu(!apceMenu)
              }}
            >
              <Link to="/" className="nav_link_main ">
                Apace
              </Link>
              <span className="flex self-center">
                <HiChevronDown
                  className={` transform transition-all duration-300 ${
                    apceMenu ? " rotate-180" : ""
                  }`}
                />
              </span>
            </div>

            <div
              className={`flex flex-col bg-slate-200  ${
                apceMenu
                  ? "max-h-96 transition-all duration-1000 ease-in-out overflow-hidden"
                  : "max-h-0  transition-all duration-700 ease-in-out overflow-hidden"
              }`}
            >
              <ul className="px-4 py-4">
                <li className="pb-3">
                  <a
                    href="#roadmap"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Our Roadmap
                  </a>
                </li>
                <li className="pb-3">
                  <a
                    href="/legal/tos"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Terms of Service
                  </a>
                </li>
                <li className="pb-3">
                  <a
                    href="/legal/privacy"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Privacy policy
                  </a>
                </li>
                <li className="pb-3">
                  <a href="/about" className="nav_link" onClick={toggleDrawer}>
                    About us
                  </a>
                </li>
                <li className="pb-3">
                  <a
                    href="#contact"
                    className="nav_link"
                    onClick={toggleDrawer}
                  >
                    Let's chat
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="pt-3">
            <div className="flex justify-between relative mb-4 cursor-pointer px-4">
              <a
                href="#marketplace"
                className="nav_link_main "
                onClick={toggleDrawer}
              >
                Marketplace
              </a>
            </div>
          </div>
          <div className="pt-3">
            <div className="flex justify-between relative mb-4 cursor-pointer px-4">
              <a
                href="https://share.hsforms.com/1bo94wr6BQqaYAL9fxl4b7gd0ydi"
                target={"_blank"}
                className="nav_link_main "
              >
                Support
              </a>
              <span className="flex self-center">
                <a
                  href="https://share.hsforms.com/1bo94wr6BQqaYAL9fxl4b7gd0ydi"
                  target={"_blank"}
                  onClick={toggleDrawer}
                >
                  <HiExternalLink
                    className={` transform transition-all duration-300`}
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  )
}

export default MobileMenu
